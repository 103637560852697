
.form-group {
  &.has-errors {
    .vue-tel-input {
      border-color: #ff5c75;
    }
    .invalid-feedback {
      display: block;
    }
  }
  .vue-tel-input {
    border: 1px solid #e2e7f1;
    &:focus {
      border-color: #5369f8;
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
