
.noti-scroll {
  height: 220px;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.button-menu-mobile {
  outline: none !important;
}
#app.repuagency{
  .navbar-custom .menu-left {
    overflow:visible;
    .button-menu-mobile {
      margin-left: -40px;
      z-index: 100000;

      i {
        background: #3b7d23;
        border-radius: 50%;
        padding: 2px;
        color: white;
        svg{
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 640px){
  .header-btns{
    text-align: center;
  }
  .btn-landing,
  .btn-create-widget{
    padding: 4px 7px;
    font-size: .75em;
    margin-right: 5px !important;
    width: 120px;
  }

}
