
.slimscroll-menu {
  height: 100%;
}
.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}
.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}
.left-side-menu {
  padding-bottom: 10px;
  a.side-nav-link {
    i {
      margin-right: 15px !important;
    }
  }
}
.left-side-menu .sidebar-content {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}
#sidebar-menu {
  flex: 1 1 auto;
}
.mm-active, .nav-second, .nav-second-level{
  background: #112144;
}
.nav-second-level{
  border-bottom: 2px solid #112144;
}
.light-variation{
  .mm-active, .nav-second, .nav-second-level{
    background: #c2bfbf;
    a{
      color: #2c2e2f;
    }
    a.router-link-active{
      color: #c2bfbf;
    }
  }
  .nav-second-level{
    border-bottom: 2px solid #c2bfbf;
  }
}
